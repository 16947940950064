import Slider from 'react-slick';
import styles from './Team.module.scss';
import './Slider.scss'

function Team(props: any) {

  var team = [
    {
      name: "Mikheil Kardenakhishvili",
      text: "Director and Founder of TECHSEED, optimizing the organization's effectiveness from the start.",
      image1: "mikheil1.png",
      image2: "mikheil2.png"
    },
    {
      name: "Nika Zoidze",
      text: "Vice President of Sales, Founder, utilizing his deep understanding of the industry to grow sales and develop the sales team.",
      image1: "nika1.png",
      image2: "nika2.png"
    },
    // {
    //   name: "Vasili Grigolaia",
    //   text: "Founder, Vice President of Engineering, Head of DevOps, and Head of Infrastructure & Network, playing a pivotal role in the company's growth.",
    //   image1: "vasili1.png",
    //   image2: "vasili2.png"
    // },
    {
      name: "Dimitri Katsiashvili",
      text: "Vice-President of the Security Department, Founder, applying his knowledge in security and compliance to guarantee the safety of TECHSEED's customers.",
      image1: "dimitri1.png",
      image2: "dimitri2.png"
    },
    {
      name: "Natia Zirakishvili",
      text: "Communications Manager, utilizing her communication and creative skills to promote TECHSEED's brand and mission effectively.",
      image1: "natia1.png",
      image2: "natia2.png"
    },
    {
      name: "Levan Pkhakadze",
      text: "Business Development Manager at TECHSEED, responsible for driving growth and securing new partnerships and opportunities.",
      image1: "levan1.png",
      image2: "levan2.png"
    },
    {
      name: "George Kardenakhishvili",
      text: "Site Reliability Engineer, consistently improving the deployment of TECHSEED's services.",
      image1: "george1.png"
    },
  ]

  var settings = {
    dots: true,
    infinite: true,
    initialSlide: 0,
    slidesToShow: 3,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 1000,
    rows: 1,
    customPaging: (i: number) => {
      return (
        <div className={styles.dot}>

        </div>)
    },
    responsive: [
      {
        breakpoint: 1610,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "187.5px"
        },
      },
      {
        breakpoint: 1540,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "187.5px"
        },
      },
      {
        breakpoint: 1160,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "90px",
          arrows: false,
          autoplay: true
        },
      },
      {
        breakpoint: 1075,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "212.5px",
          arrows: false,
          autoplay: true
        },
      },
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "112.5px",
          arrows: false,
          autoplay: true
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "3px",
          arrows: false,
          autoplay: true
        },
      },
      {
        breakpoint: 470,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "5px",
          arrows: false,
          autoplay: true
        },
      },
    ],
  };

  return (
    <div className={styles.stars + " teamStars " + (props.isLight ? styles.Light : styles.Dark)}>

      <div className={styles.header}>our <p className={styles.headerp}>team</p></div>

      <div className={styles.rectangles}>
        <div className={styles.rectanglesChild}>
          <Slider {...settings}>
            {team.map((item, index) => {
              if (index === team.length - 1) {
                return (
                  <div className={styles.team} key={index}>
                  <div className={styles.top + " " + styles['team' + (index === 6 ? 2 : index % 3 + 1)]}>
                    <img src={require('../assets/Images/' + item.image1)} style={{opacity: "1"}} className={styles.image1 + " " + (item.name === "Levan Pkhakadze" ? styles.wide : "")} alt="" />
                  </div>

                  <div className={styles.bottom + " " + styles['team' + (index === 6 ? 2 : index % 3 + 1)]}>
                    <div className={styles.name}>
                      {item.name}
                    </div>
                    <div className={styles.description}>
                      {item.text}
                    </div>
                  </div>
                </div>
                );
            } else return (
                <div className={styles.team} key={index}>
                  {/* 6 members, last item is the same color */}
                  {/* hard coded 6th item to team2 style */}
                  <div className={styles.top + " " + styles['team' + (index === 6 ? 2 : index % 3 + 1)]}>
                    <img src={require('../assets/Images/' + item.image1)} className={styles.image1 + " " + (item.name === "Levan Pkhakadze" ? styles.wide : "")} alt="" />
                    <img src={require('../assets/Images/' + item.image2)} className={styles.image2 + " " + (item.name === "Levan Pkhakadze" ? styles.wide : "")} alt="" />
                  </div>

                  <div className={styles.bottom + " " + styles['team' + (index === 6 ? 2 : index % 3 + 1)]}>
                    <div className={styles.name}>
                      {item.name}
                    </div>
                    <div className={styles.description}>
                      {item.text}
                    </div>
                  </div>
                </div>)
            })}
          </Slider>

        </div>
      </div>
      <div className={styles.portfolio}>
        <p>10+ years</p> of <p>experience</p>
      </div>


    </div >
  );
}

export default Team;
import { Helmet } from 'react-helmet';
import styles from './TeamPage.module.scss';

function TeamPage(props: any) {

    var team = [
        {
            name: "Mikheil Kardenakhishvili",
            text: "Director and Founder of TECHSEED, optimizing the organization's effectiveness from the start.",
            image1: "mikheil1.png",
            image2: "mikheil2.png"
        },
        {
            name: "Nika Zoidze",
            text: "Vice President of Sales, Founder, utilizing his deep understanding of the industry to grow sales and develop the sales team.",
            image1: "nika1.png",
            image2: "nika2.png"
        },
        // {
        //     name: "Vasili Grigolaia",
        //     text: "Founder, Vice President of Engineering, Head of DevOps, and Head of Infrastructure & Network, playing a pivotal role in the company's growth.",
        //     image1: "vasili1.png",
        //     image2: "vasili2.png"
        // },
        {
            name: "Dimitri Katsiashvili",
            text: "Vice-President of the Security Department, Founder, applying his knowledge in security and compliance to guarantee the safety of TECHSEED's customers.",
            image1: "dimitri1.png",
            image2: "dimitri2.png"
        },
        {
            name: "Natia Zirakishvili",
            text: "Communications Manager, utilizing her communication and creative skills to promote TECHSEED's brand and mission effectively.",
            image1: "natia1.png",
            image2: "natia2.png"
        },
        {
            name: "Levan Pkhakadze",
            text: "Business Development Manager at TECHSEED, responsible for driving growth and securing new partnerships and opportunities.",
            image1: "levan1.png",
            image2: "levan2.png"
        },
        // {
        //     name: "Davit Kirvalidze",
        //     text: "Site Reliability Engineer, consistently improving the deployment of TECHSEED's services.",
        //     image1: "george1.png",
        //     image2: "george1.png",
        // },
        // {
        //     name: "Micheil Ioseliani",
        //     text: "Site Reliability Engineer, consistently improving the deployment of TECHSEED's services.",
        //     image1: "george1.png",
        //     image2: "george1.png",
        // },
        {
            name: "George Kardenakhishvili",
            text: "Site Reliability Engineer, consistently improving the deployment of TECHSEED's services.",
            image1: "george1.png",
            image2: "george1.png",
        },
    ]

    return (
        <div className={styles.main}>
            <Helmet>
                <meta name="description" content="Find out more about our team."></meta>
                <title>Our Team</title>
            </Helmet>
            <div className={styles.header}>
                <div className={styles.title}><p>our</p><p> team</p></div>
            </div>

            <div className={styles.grid}>
                {team.map((item, index) => {
                    // if (index === team.length - 1) {
                    //     return null;
                    // } else 
                    if (item.name === "Levan Pkhakadze") {
                        return (
                            <div className={styles.team + " " + styles['team' + (index + 1)]} key={index}>
                                <div className={styles.top + " " + styles.levan}>
                                    <img src={require('../assets/Images/' + item.image1)} className={styles.image1} alt="" />
                                    <img src={require('../assets/Images/' + item.image2)} className={styles.image2} alt="" />
                                </div>
                                <div className={styles.bottom}>
                                    <p className={styles.name}>{item.name}</p>

                                    <p className={styles.description}>{item.text}</p>
                                </div>
                            </div>
                        )
                    }
                    return (
                        <div className={styles.team + " " + styles['team' + (index + 1)]} key={index}>
                            <div className={styles.top}>
                                <img src={require('../assets/Images/' + item.image1)} className={styles.image1} alt="" />
                                <img src={require('../assets/Images/' + item.image2)} className={styles.image2} alt="" />
                            </div>
                            <div className={styles.bottom}>
                                <p className={styles.name}>{item.name}</p>

                                <p className={styles.description}>{item.text}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
            {/* <div className={styles.team + " " + styles.last}>
                <div className={styles.top}>
                    <img src={require('../assets/Images/' + team[team.length - 1].image1)} className={styles.image1} alt="" />
                </div>
                <div className={styles.bottom}>
                    <p className={styles.name}>{team[team.length - 1].name}</p>

                    <p className={styles.description}>{team[team.length - 1].text}</p>
                </div>
            </div> */}
        </div>)
}

export default TeamPage;
import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import {
    useGoogleReCaptcha
} from 'react-google-recaptcha-v3';
import styles from './Calendar.module.scss';
import './Calendar.scss';
import BookStars from '../book-stars/BookStars';
import { Helmet } from 'react-helmet';


function TalkToUs(props: any) {
    return (
        <div className={styles.main + " " + (props.isLight ? styles.Light : styles.Dark)}>
            <Helmet>
                <meta name="description" content="Schedule a call with us."></meta>
                <title>Talk to Us</title>
            </Helmet>

            <div className={styles.header}>
                Talk to us
            </div>

            <div className={styles.textContainer}>
                <div className={styles.text}>
                    <p className={styles.red}>TECHSEED</p> offers <p className={styles.purple}>comprehensive</p>, customer-oriented <p className={styles.blue}>solutions </p> for <p className={styles.red}>cybersecurity</p>, <p className={styles.blue}>devops</p> and IT infrastructure, with planning, implementation, coordination and maintenance.
                    Contact us today and see the difference.
                </div>
            </div>


            <div>
                <div className={styles.calendar}>
                    <BookStars isLight={props.isLight}></BookStars>
                </div>
            </div>
        </div>)
}

export default TalkToUs;